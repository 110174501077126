<template>
	<div class="s-canvas">
		<canvas id="s-canvas" :width="contentWidth" :height="contentHeight" @click="handleClick"></canvas>
		<el-link style="margin-left: 10px;color: #5fc5ff;text-decoration: underline;" @click="handleClick">换一张</el-link>
	</div>
</template>
<script>
	export default {
		name: 'SIdentify',
		data() {
			return {
				identifyCode: '',
			}
		},
		props: {
			fontSizeMin: {
				type: Number,
				default: 32
			},
			fontSizeMax: {
				type: Number,
				default: 40
			},
			backgroundColorMin: {
				type: Number,
				default: 180
			},
			backgroundColorMax: {
				type: Number,
				default: 240
			},
			colorMin: {
				type: Number,
				default: 50
			},
			colorMax: {
				type: Number,
				default: 160
			},
			lineColorMin: {
				type: Number,
				default: 40
			},
			lineColorMax: {
				type: Number,
				default: 180
			},
			dotColorMin: {
				type: Number,
				default: 0
			},
			dotColorMax: {
				type: Number,
				default: 255
			},
			contentWidth: {
				type: Number,
				default: 136
			},
			contentHeight: {
				type: Number,
				default: 48
			}
		},
		methods: {
			//点击生成随机数 并传给调用它的组件（父组件用changeCode接收）
			handleClick() {
				let num = "";
				for (let i = 0; i < 4; i++) {
					num += Math.floor(Math.random() * 10)
				}
				// //console.log('生成',num)
				this.identifyCode = num
				let newN = this.identifyCode
				this.$emit('changeCode', newN)
			},
			// 生成一个随机数
			randomNum(min, max) {
				return Math.floor(Math.random() * (max - min) + min)
			},
			// 生成一个随机的颜色
			randomColor(min, max) {
				var r = this.randomNum(min, max)
				var g = this.randomNum(min, max)
				var b = this.randomNum(min, max)
				return 'rgb(' + r + ',' + g + ',' + b + ')'
			},
			drawPic() {
				var canvas = document.getElementById('s-canvas')
				var ctx = canvas.getContext('2d')
				ctx.textBaseline = 'bottom'
				// 绘制背景
				ctx.fillStyle = this.randomColor(
					this.backgroundColorMin,
					this.backgroundColorMax
				)
				ctx.fillRect(0, 0, this.contentWidth, this.contentHeight)
				// 绘制文字
				for (let i = 0; i < this.identifyCode.length; i++) {
					this.drawText(ctx, this.identifyCode[i], i)
				}
			},
			drawText(ctx, txt, i) {
				ctx.fillStyle = this.randomColor(this.colorMin, this.colorMax)
				ctx.font =
					this.randomNum(this.fontSizeMin, this.fontSizeMax) + 'px SimHei'
				var x = (i + 1) * (this.contentWidth / (this.identifyCode.length + 1))
				var y = this.randomNum(this.fontSizeMax, this.contentHeight - 5)
				var deg = this.randomNum(-45, 45)
				// 修改坐标原点和旋转角度
				ctx.translate(x, y)
				ctx.rotate(deg * Math.PI / 180)
				ctx.fillText(txt, 0, 0)
				// 恢复坐标原点和旋转角度
				ctx.rotate(-deg * Math.PI / 180)
				ctx.translate(-x, -y)
			},
		},
		watch: {
			identifyCode() {
				this.drawPic()
			}
		},
		mounted() {
			this.drawPic()
			this.handleClick()
		}
	}
</script>
<style lang="scss">
	.s-canvas{
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}
	#s-canvas{
		cursor: pointer;
	}
</style>